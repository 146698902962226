<template>
  <div class="profile-corner">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="changeLockAction"
        >
          <font-awesome-icon icon="lock" :class="{ hidden: loading }" />
          <!-- <div class="spinner-border mr-1" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div> -->
          <v-progress-circular
            v-if="loading"
            class="mr-2 mt-1"
            indeterminate
            width="2"
            size="10"
            color="#704ad1"
          ></v-progress-circular>
        </div>
      </template>
      <span>Verrouiller / Deverrouiller</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    className: { default: '' },
    grouped: { default: null }
  },
  data() {
    return {
      loading: false,
      error: null
    };
  },
  computed: {},
  methods: {
    ...mapActions(['changeLockProjectsTh']),
    async changeLockAction() {
      this.loading = true;
      await this.changeLockProjectsTh(this.grouped);
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.spinner-border {
  height: 15px;
  width: 15px;
}
</style>
